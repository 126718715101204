@import "../../../style-variables.scss";

.expand-number::-webkit-inner-spin-button,
.expand-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.expand-number {
  max-width: 36px;
  margin-left: -1px;
  text-align: center;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #6c757d !important;
    text-align: center;
    opacity: 1; /* Firefox */
    position: relative;
    top: -2px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #6c757d !important;
    text-align: center;
    position: relative;
    top: -2px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #6c757d !important;
    text-align: center;
    position: relative;
    top: -2px;
  }
}

.creatable-select {
  [id^="react-select"] {
    position: relative;
    left: 0.15rem;
  }
}
