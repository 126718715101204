@import "../../../style-variables.scss";

.deliverables-table {
  + .section-total {
    margin-top: calc(-1rem - 1px);
    tfoot {
      background: lighten($orange, 30%) !important;
    }
  }
}

.quick-edit-form {
  pointer-events: none;
  opacity: 0;
  background: white;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.05), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  padding: 0px 10px;
  border-radius: 4px;
  margin-bottom: 0;
  position: relative;
  border: 0;
  height: 0;
  transition: height $transition, padding $transition, margin $transition,
    opacity $transition;
  display: flex;
  align-items: center;
  > div {
    margin-top: -2px;
    flex: 1;
    display: flex;
    align-items: center;
  }
  .form-group {
    margin-bottom: 0;
  }
  &:after,
  &:before {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &.arrow-bottom {
    &:after {
      top: 100%;
      left: 5%;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: white;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      top: 100%;
      left: 5%;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: hsla(0, 0%, 0%, 0.05);
      border-width: 11px;
      margin-left: -11px;
    }
    &.right {
      &:after {
        left: auto;
        right: 5%;
      }
      &:before {
        left: auto;
        right: 5%;
      }
    }
  }
  &.arrow-top {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.05),
      0 -4px 11px hsla(0, 0%, 0%, 0.1);
    &:after {
      bottom: 100%;
      right: 5%;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: white;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      bottom: 100%;
      right: 5%;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: hsla(0, 0%, 0%, 0.05);
      border-width: 11px;
      margin-left: -11px;
    }
  }
  &.show {
    opacity: 1;
    pointer-events: all;
    height: 64px;
    margin-bottom: 1rem;
    // padding: 10px;
  }
  .MuiFormHelperText-root {
    display: none;
  }
}

.hide-me + .section-total {
  margin-top: 0;
}

.deliverable-table-container {
  display: flex;
  flex-direction: column;
}

// $columns: existing-spend, new-spend, pass-through;

// @each $column in $columns {
//   @for $i from 2 through 20 {
//     tr.expand-#{$column}-#{$i} {
//     ~ tr:nth-of-type(-n + #{$i + 1}) {
//         td.#{$column} {
//           display: none;
//         }
//       }
//     }
//   }
// }

tr.expand-existing-spend-2 {
  + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-3 {
  + tr,
  + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-4 {
  + tr,
  + tr + tr,
  + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-5 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-6 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-7 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-8 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-9 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-10 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-11 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-12 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-13 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-14 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-15 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-16 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-17 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-18 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-19 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-existing-spend-20 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.existing-spend {
      display: none;
    }
  }
}

tr.expand-pass-through-2 {
  + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-3 {
  + tr,
  + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-4 {
  + tr,
  + tr + tr,
  + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-5 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-6 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-7 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-8 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-9 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-10 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-11 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-12 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-13 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-14 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-15 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-16 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-17 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-18 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-19 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-pass-through-20 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.pass-through {
      display: none;
    }
  }
}

tr.expand-new-spend-2 {
  + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-3 {
  + tr,
  + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-4 {
  + tr,
  + tr + tr,
  + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-5 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-6 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-7 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-8 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-9 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-10 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-11 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-12 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-13 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-14 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-15 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-16 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-17 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-18 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-19 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}

tr.expand-new-spend-20 {
  + tr,
  + tr + tr,
  + tr + tr + tr,
  + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr,
  + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr + tr {
    td.new-spend {
      display: none;
    }
  }
}


