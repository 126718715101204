@import "../../style-variables.scss";

#brief-table-container {
  margin-bottom: 0;
  thead {
    background: transparent;
  }
  > tbody,
  > thead {
    > tr {
      > th,
      > td {
        border: 0 !important;
      }
    }
  }
}

#brief-list {
  th:last-of-type {
    width: 150px;
  }
  tbody,
  thead,
  tr {
    display: flex;
  }
  th,
  td {
    &:first-of-type {
      flex: 1;
    }
    &:last-of-type {
      width: 150px;
    }
  }
}

#brief-document {
  background: white;
}

#header-table {
  th,
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.briefs.doc-view {
  overflow: auto;
  position: relative !important;
  min-height: 100vh;
  .logos {
    margin-bottom: 10px;
  }
  .table {
    background: transparent;
    tr {
      display: table-row;
      th {
        background: lighten($orange, 30%);
        // width: 150px;
      }
      td:not(.editable) {
        background: transparent;
      }
    }
    .editable {
      padding: 0;
      input {
        border-radius: 0;
        border-color: transparent;
        border: 0;
        &:not(:focus) {
          cursor: pointer;
        }
      }
    }

    .tox-tinymce {
      border: 0 !important;
    }

    .tox-statusbar {
      display: none;
    }

    .tox .tox-sidebar-wrap {
      margin-bottom: -50px;
    }

    .mce-content-body {
      border: 0 !important;
      border-radius: 0 !important;
      min-height: auto !important;
      max-height: unset !important;
      padding: 0.375rem 0.75rem;
      overflow: hidden;
      * {
        &:last-child {
          margin-bottom: 0;
        }
      }

      &.mce-edit-focus {
        // box-shadow: inset 0 0 0px 1px lighten($link, 20%);
        box-shadow: inset 0 0 0px 2px #12b796;
      }

      &:not(:focus) {
        cursor: pointer;

        .mce-spellchecker-word {
          background: none;
        }
      }
    }

    .mce-visual-caret.mce-visual-caret-hidden {
      display: none;
    }
    [data-mce-caret] {
      display: none;
    }
  }
}

.brief-table {
  tr {
    page-break-inside: avoid;
    > th {
      width: 120px;
    }
  }
}

#briefs {
  &:not(.briefs-view) {
    margin-top: 10px !important;
  }
  #undo-redo,
  #options-btn {
    display: none;
  }

  a {
    position: relative;
  }

  .date,
  .team {
    max-width: 120px;
  }

  .action {
    max-width: 76px;
  }

  form {
    margin-top: 10px;
    .form-row {
      &:last-of-type {
        .form-group {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .form-control,
  .form-label {
    background: white;
    color: #212529;

    &[name^="project"] {
      padding: 0.3rem;
      height: 33px;
    }
  }

  .form-control {
    &:focus {
      box-shadow: inset 0 0 0px 3px lighten($link, 20%);
    }
  }

  #spinner {
    // top: 0;
    z-index: 9999;
  }
}

#timeline-table {
  table-layout: fixed !important;
  margin-bottom: 0;
  th:first-of-type {
    width: 80px !important;
  }
  th:last-of-type {
    width: 120px !important;
  }
  tr {
    page-break-inside: avoid;
  }
}

@media print {
  html,
  body {
    background: white !important;
    .MuiTooltip-popper {
      display: none !important;
    }
  }
  th {
    box-shadow: inset 0 0 0 1000px #fadec7 !important;
  }
  .badge {
    border: 0 !important;
  }
  #app-container.briefs.doc-view {
    top: 0;
    .alert,
    .MuiAlert-root,
    .MuiOutlinedInput-notchedOutline,
    form,
    .deliverable-toolbar,
    .tox,
    .tox-editor-header,
    #cannot-edit {
      display: none !important;
    }
    #briefs {
      margin-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .tox .tox-sidebar-wrap {
      margin-bottom: -90px;
    }
  }
}
