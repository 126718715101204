@import "../../style-variables.scss";

// #reports {
//   #doc-list {
//     font-size: 14px;
//
//     tbody {
//       height: calc(100vh - 360px);
//     }
//     thead {
//       th {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         svg {
//           display: none;
//         }
//         &.active {
//           background: #f7f7f7;
//           svg {
//             display: inline-block;
//           }
//         }
//         cursor: pointer;
//         white-space: nowrap;
//         &:hover {
//           background: #f7f7f7;
//         }
//         &.asc {
//           svg {
//             transform: rotateX(180deg);
//             transform-origin: center;
//           }
//         }
//       }
//     }
//     tr {
//       td,
//       th {
//         padding: 0.2rem;
//         &:first-of-type {
//           flex: 1;
//           max-width: 442px;
//         }
//         &:nth-of-type(2) {
//           width: 120px;
//         }
//         &:nth-of-type(3) {
//           width: 140px;
//         }
//         &:nth-of-type(4) {
//           width: 170px;
//         }
//         &:nth-of-type(5) {
//           width: 110px;
//         }
//         &:last-of-type {
//           width: 120px;
//         }
//       }
//     }
//     a {
//       max-width: 100%;
//
//       span {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }
//     }
//
//     .badge {
//       min-width: 83px;
//     }
//     .contact,
//     button {
//       text-decoration: none !important;
//       font-size: inherit;
//       text-align: left;
//       max-width: 140px;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       > p {
//         margin: 0;
//         overflow: hidden;
//         line-height: 21px;
//         max-height: 21px;
//         -webkit-line-clamp: 1;
//         -webkit-box-orient: vertical;
//         ~ p {
//           display: none;
//         }
//       }
//       span {
//         // display: none;
//       }
//     }
//   }
//   .currency {
//     // padding-left: 15px !important;
//     span {
//       cursor: pointer;
//       transition: color $transition;
//       &:hover {
//         color: $link;
//       }
//     }
//   }
//
//   .carousel-control-next,
//   .carousel-control-prev {
//     color: inherit;
//     font-size: 40px;
//     width: 40px;
//   }
//   .carousel-control-next {
//     right: -40px;
//   }
//   .carousel-control-prev {
//     left: -40px;
//   }
// }

.report-carousel {
  overflow: visible !important;

  button {
    top: auto !important;
    svg {
      font-size: 3rem;
    }
  }

  // div[class*="Carousel-next"] {
  //   transform: translateY(-50%) translateX(50px);
  // }
  // div[class*="Carousel-prev"] {
  //   transform: translateY(-50%) translateX(-50px);
  // }
}

#report-totals {
  background: #f2f8fd;
  box-shadow: $boxShadow;
  z-index: 5;
  .MuiGrid-root {
    padding: 15px 0;
    > * {
      margin: 0;
    }
  }
  .MuiTypography-root {
    display: flex;
    flex-wrap: wrap;
    > span {
      position: relative;
      white-space: nowrap;
      cursor: pointer;
      &:not(:last-of-type) {
        margin-right: 20px;
      }

      h6 {
        position: absolute;
        bottom: 100%;
        margin: 0;
        margin-left: 2px;
        font-size: 9px;
        opacity: 0.7;
      }

      transition: color $transition;
      &:hover {
        color: $link;
      }
    }
  }
}

#totals-table {
  font-size: 14px;

  .currency {
    pointer-events: none;
    text-align: right;
  }

  thead {
    th {
      white-space: nowrap;
    }
  }

  tbody {
    td {
      &:first-of-type {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
      }
    }
  }
}

.recharts-tooltip-wrapper {
  li {
    text-transform: capitalize;
  }
}

// .cost-breakdown {
//   list-style: none;
//   margin: 0 !important;
//   padding: 0;
//   &.table {
//     td {
//       border: 0 !important;
//     }
//   }
// }
