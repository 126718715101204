@import "../../style-variables.scss";

#ioc-btn {
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 2;
  // button:not(:hover) {
  //   border-color: transparent;
  //   background: transparent;
  // }
}

.editable {
  &.selected {
    box-shadow: inset 0 0 0px 2px rgba($link, 0.4);
  }
}

.section-title {
  color: $orange;
}

#pdf {
  display: none;
  line-height: 1.3;
}

.table-group {
  position: relative;
  transition: background $transition;
  .table-toolbar {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
  }
  &:hover {
    background: rgba($link, 0.05);
    .table-toolbar {
      display: block;
    }
  }
}

// #edit-split {
//   min-width: 60px;
// }

#container {
  max-width: 100vw;
  padding-top: 80px;
  > div:not(#content),
  > h1,
  > hr,
  // #content > section, #reorder > section
  {
    // padding: 10px;
  }
  &.editor-open {
    padding-bottom: 100vh;
  }
  &.preview {
    background: inherit;
    margin: 0;
    margin-top: 60px;
    padding: 0;
    max-width: 100vw;
    section {
      padding: 0;
    }
    // #top-bar {
    //   position: relative;
    // }
    hr {
      border-color: black;
    }
    #undo-redo {
      visibility: hidden;
      display: none;
    }
    #steps,
    .document,
    #approve-bar,
    #top-nav {
      display: none;
    }
    #pdf {
      .add-below {
        + hr {
          margin-bottom: 1rem !important;
        }
      }
      > h4,
      h6 {
        svg {
          font-size: 20px;
        }
      }
      display: block;
      margin: 0 auto;
      margin-top: 50px;
      .page {
        display: block;
        position: relative;
        // outline: 1px solid red;
        // margin-bottom: 40px;
        // page-break-after: always;
        // page-break-inside: avoid;
        .editable {
          &:hover,
          &.active {
            background: inherit;
          }
        }
        tr.deliverable-category,
        #index-of-contents a {
          pointer-events: none;
        }
      }
      .total-table {
        width: auto !important;
        max-width: 100% !important;
      }
      table {
        background: inherit;
        &.events-table {
          td {
            word-break: break-word;
          }
        }
      }
      * {
        -webkit-print-color-adjust: exact;
        // pointer-events: none;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
      hr {
        margin-top: 4.233333333mm;
        margin-bottom: 4.233333333mm;
      }
      // [class^="col-"] {
      //   padding-right: 3.96875mm;
      //   padding-left: 3.96875mm;
      // }
      .h1,
      .h2,
      .h3,
      .h4,
      .h5,
      .h6,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin-bottom: 4.233333333mm;
      }
      // p:last-of-type {
      //   margin-bottom: 0;
      // }
      p,
      li,
      table,
      #index-of-contents {
        font-size: 10pt;
        font-size: 3.52778mm;
        word-break: break-word;
      }
      li * {
        font-size: 3.52778mm !important;
      }
      .table-sm td,
      .table-sm th {
        // padding: 0mm;
        height: 25px;
      }
      .small {
        font-size: 8pt;
        font-size: 2.82222mm;
      }
      h3 {
        font-size: 18pt;
        // font-size: 4.93889mm;
      }
      h4 {
        font-size: 16pt;
        // font-size: 3.52778mm;
      }
      h5 {
        // font-size: 11pt;
        // font-size: 3.88056mm;
        font-size: 14pt;
        font-size: 4.93889mm;
      }
      h6 {
        font-size: 10pt;
        font-size: 3.52778mm;
      }
      .logos {
        img {
          max-height: 30pt;
          max-height: 10.58334mm;
        }
      }
      #sig-required {
        font-size: 10pt;
        font-size: 3.52778mm;
      }
      footer {
        font-size: 7.5pt;
        font-size: 7.2.64583mm;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0mm 15mm;
      }
      section {
        overflow: hidden;
        // margin-top: 10px;
        // &::-webkit-scrollbar {
        //   display: none;
        // }
        img {
          max-width: 100%;
        }
        hr:first-child {
          margin-top: 0px;
        }
        &.deliverable-item {
          &:before {
            display: none;
          }
          &:first-child {
            hr {
              display: none;
            }
          }
          &:before {
            font-size: 10pt;
            font-size: 3.52778mm;
            top: 11pt;
            top: 3.88056mm;
          }
          hr {
            margin-left: -40px;
          }
          h4,
          h6 {
            position: relative;
            span:not(.orange) {
              color: black;
              position: absolute;
              // left: -25px;
              right: calc(100% + 5px);
              // top: -1px;
            }
          }
        }
        &.appendix-item {
          page-break-before: always;
          > h6:first-of-type {
            &:before {
              display: none;
            }
          }
        }
        &.page-break {
          page-break-before: always;
        }
        // table:last-of-type {
        //   margin-bottom: 0;
        //   + * {
        //     margin-top: 1rem;
        //   }
        // }
      }
      #contact-info {
        p:last-of-type {
          margin-bottom: 0;
        }
      }
      #index-of-contents {
        // li {
        //   &:nth-child(2),
        //   &:nth-child(3) {
        //     display: none;
        //   }
        // }
        .company-name {
          display: none;
        }
        a {
          &:before {
            display: none;
          }
        }
      }
      // .header,
      // .logos,
      // .header-space,
      // .footer,
      // .footer-space {
      //   height: 150px;
      // }
      // .header,
      // .logos {
      //   position: fixed;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      // }
      // .footer {
      //   position: fixed;
      //   bottom: 0;
      //   left: 0;
      //   right: 0;
      // }
      #page-3 {
        #section-0,
        #section-1,
        #section-2 {
          display: none;
        }
      }
      .company-logo-container {
        pointer-events: none;
      }
      .editable {
        // background: none !important;
        cursor: default !important;
      }
    }
  }
  &:not(.admin) {
    button:not(.public-btn),
    .button:not(.public-btn),
    // .form-control,
    .floating-label,
    .custom-control, #main-tab form, #accordions form, #accordions .team {
      pointer-events: none !important;
      opacity: 0.5 !important;
      cursor: not-allowed !important;
    }
    #add-section-btn,
    .no-logo,
    .deliverable-toolbar,
    .table-toolbar,
    .quick-edit-form {
      display: none !important;
    }
    .editable,
    .company-logo-container {
      pointer-events: none !important;
    }
    #top-bar .btn-group:not(:first-of-type) {
      visibility: hidden !important;
    }
  }
  &:not(.admin):not(.locked-out) {
    #cannot-edit {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1031;
      visibility: visible !important;
      pointer-events: none;
    }
    #extend-btn {
      display: none;
    }
    #undo-redo {
      + .d-flex {
        overflow: visible !important;
      }
    }
    #doc-title {
      position: relative;
      overflow: visible;
      // &:after {
      //   content: "Not editable (you can only edit one SOW at a time)";
      //   position: absolute;
      //   font-size: 0.75rem;
      //   width: 200px;
      //   padding: 0 10px;
      //   color: var(--danger);
      //   white-space: nowrap;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   opacity: 0.7;
      // }
    }
    #steps {
      // display: none;
      // top: 70px;
      .accordion {
        display: none !important;
      }
    }
    // #document {
    //   margin-left: auto;
    // }
  }
  &.master-template {
    #additional-settings {
      .btn:not(.public-btn) {
        display: none;
        + br {
          display: none;
        }
      }

      .public-btn {
        + br {
          display: none;
        }
      }
    }
  }
  &.approved {
    .Mui-disabled {
      opacity: 0.7;
    }
    button:not(.public-btn),
    .button:not(.public-btn),
    // .form-control,
    .floating-label,
    .custom-control,
    .react-select,
    .input-group-text,
    // .MuiFormControl-root,
    // .MuiAutocomplete-root
    {
      // pointer-events: none !important;
      // opacity: 0.5 !important;
      // cursor: not-allowed !important;
    }
    // .MuiAutocomplete-root .MuiFormControl-root {
    //   opacity: 1 !important;
    // }
    #main-settings .btn {
      display: none;
    }
    #additional-settings {
      .btn:not(.public-btn) {
        display: none;
        + br {
          display: none;
        }
      }
    }
    .editable,
    .company-logo-container {
      pointer-events: none !important;
    }
    #undo-redo {
      visibility: hidden !important;
    }
    #preview-btn {
      // float: right;
    }
    #top-bar {
      // .container {
      //   justify-content: center !important;
      // }
      .btn-group {
        .btn:not(#preview-btn):not(#print-btn):not(#leave-preview-btn):not(#settings-btn):not(.public-btn) {
          display: none;
        }
      }
    }
    .accordion {
      .card:not(#approve-card) {
        display: none !important;
      }
      #approve-card {
        button,
        .editable {
          pointer-events: all !important;
          opacity: 1 !important;
          cursor: pointer !important;
        }
        .card-header {
          pointer-events: none;
        }
        .card-body > *:not(.file-upload-container):not(#attached-files) {
          display: none;
        }
        .collapse {
          display: block !important;
          height: auto !important;
        }
      }
    }
    #approve-bar {
      background: $green;
      color: white;
      p {
        margin: 0;
      }
    }
  }
  &.super-admin {
    .public-select {
      pointer-events: all !important;
      opacity: 1 !important;
      cursor: inherit !important;
    }
  }
  // &.need-company {
  //   #crm-company-select,
  //   #apply-btn button {
  //     pointer-events: all !important;
  //     opacity: 1 !important;
  //     display: block !important;
  //     cursor: pointer !important;
  //   }
  // }
  // &.sent-to-client {
  //   .draft {
  //     display: none !important;
  //   }
  // }
  &:not(.approved):not(.sent-to-client):not(.has-token):not(.revised) {
    .page:after {
      content: "Draft—Not for Client";
      position: absolute;
      font-weight: bold;
      white-space: nowrap;
      text-transform: uppercase;
      color: var(--danger);
      opacity: 0.1;
      left: 50%;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20mm;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
  }
  &.has-token {
    margin-top: -50px;
    margin-bottom: 300px;
    #toc {
      margin-bottom: 300px;
    }
    #steps {
      top: 0 !important;
    }
    #approve-bar {
      button:not(:disabled),
      .button:not(:disabled),
      .floating-label,
      .form-control,
      .custom-control {
        pointer-events: all !important;
        opacity: 1 !important;
        cursor: inherit !important;
      }
      button:not(:disabled),
      .button:not(:disabled) {
        cursor: pointer !important;
      }
    }
  }
}

.editable {
  cursor: pointer;
  transition: background $transition;
  &:hover {
    background: rgba($link, 0.05);
  }
  &.active {
    background: rgba($link, 0.08);
  }
}

#edit-quantity {
  max-width: 106px;
}

#edit-custom-price {
  text-align: right;
}

input.currency {
  max-width: 152px;
  // text-align: right;
}

.form-row {
  position: relative;
}

#edit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
  color: white;
  text-align: center;
  padding: 25px;
  svg {
    color: var(--warning);
    font-size: 40px;
    margin-bottom: 15px;
  }
}

.img-page {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  margin-bottom: 10mm;
  display: block;
}

.page {
  width: $pageW;
  height: $pageH;
  padding: 10mm 15mm;
  margin: 0mm auto;
  margin-bottom: 10mm;
  // border: 1px #d3d3d3 solid;
  border-radius: 0;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  &:first-of-type {
    margin-top: 0;
  }
  .logos {
    margin-bottom: 20px;
    margin-bottom: 5.29167mm;
  }
  // h4 {
  //   color: $orange;
  // }
  *::-webkit-scrollbar {
    display: none;
  }
  &:not(.branded):not(:first-of-type) {
    padding-top: calc(10mm + 101.66px);
    padding-bottom: calc(10mm + 44px);
  }
  &.page-break,
  &.ty-page {
    background: white;
    color: #ed591a;
    text-align: center;
    h1 {
      font-weight: bold;
      margin-top: 20mm;
    }
  }
  &.ty-page {
    height: $pageH - $pageH/2;
  }
  section img {
    margin: 0 auto;
    // max-height: calc(277mm - 101.66px - 44px - 38px);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  input#logo-upload {
    display: none;
  }
}

#sig-block {
  > div:first-of-type {
    img {
      left: 0;
      transform: none;
    }
  }
}

.section-overflowing {
  box-shadow: inset 0px 0px 0px 1px red;
}
.overflowing,
.tr-overflowing {
  box-shadow: inset 0px 0px 0px 1px blue;
}

#app-container.doc-view {
  background: white;
  > .container {
    > .alert {
      margin-top: 20px;
    }
  }
}

@media print {
  @page {
    size: a4;
  }
  html,
  body {
    // overflow: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    width: $pageW;
    height: $pageH;
    > img,
    canvas {
      width: 100%;
      margin: 0 auto;
      page-break-after: always;
      page-break-before: always;
    }
  }
  html {
    overflow-y: auto;
  }
  #app-container {
    position: relative;
    // overflow: hidden;
    top: 0;
  }
  .img-page {
    width: 100%;
    margin: 0;
    box-shadow: none !important;
  }
  .page {
    display: none !important;
    padding: 0;
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    box-shadow: none !important;
  }
  #user-menu,
  #top-bar,
  #steps,
  #print-warning,
  #editor-drawer,
  #alert,
  .popover,
  .tooltip,
  .MuiSnackbar-root,
  div[role="presentation"],
  #approve-bar,
  #top-nav,
  #cannot-edit {
    display: none !important;
  }
  #print {
    display: flex !important;
    top: 0 !important;
  }
  #document {
    margin: 0;
    max-width: 100%;
  }
  // .logos {
  //   position: fixed;
  //   top: 0;
  // }
  // div, section {
  //   page-break-inside: avoid;
  // }
  section:not(.deliverable-item) {
    // padding-top: 0;
    .section-title {
      margin-top: -3.80999952mm;
    }
  }
}

#generated-tables {
  position: relative;
  // margin-bottom: 32px;
  .spinner-border,
  .MuiCircularProgress-root {
    display: none;
  }
  &.loading {
    .spinner-border,
    .MuiCircularProgress-root {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    > *:not(.spinner-border):not(.MuiCircularProgress-root) {
      opacity: 0;
    }
  }
}

#print {
  display: none;
  // display: flex;
}

// .hide-me:not(.unhide-me) {
//   display: none !important;
//   // visibility: collapse !important;
//   // margin: 0 !important;
//   // padding: 0 !important;
// }

// .scanned {
.hide-me:not(.unhide-me) {
  display: block !important;
  visibility: collapse !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 0 !important;
  min-height: 0 !important;
  max-height: 0 !important;
  line-height: 0 !important;
  border: none !important;
  // position: absolute !important;
  * {
    height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
li.hide-me:not(.unhide-me) {
  display: list-item !important;
}
tr.hide-me:not(.unhide-me) {
  display: table-row !important;
}
ul:not(.hide-me) {
  li.hide-me:first-of-type {
    margin-bottom: -1rem !important;
  }
}
// }

.loaded-pdf {
  #app-container.doc-view {
    background: inherit;
  }
  #print {
    display: flex;
    display: none;
  }
  // &:not(.debug) {
  //   .page {
  //     display: none !important;
  //   }
  // }
}
.first-visible {
  box-shadow: inset 0px 0px 0px 1px blue;
}

.hide-me {
  + li:not(.hide-me),
  + ul.list-overflowing {
    padding-top: 1rem;
  }
}

.file-upload-container {
  outline: 3px dashed rgba($link, 0.46);
  background: rgba($link, 0.08);
  min-height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    label {
      opacity: 1;
    }
  }
  label {
    font-weight: bold;
    color: rgba($link, 0.46) !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity $transition;
  }
  svg {
    font-size: 25px;
  }
  img {
    width: 100%;
  }
}

input#file-upload {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
}

#approve-bar {
  background: white;
  box-shadow: -15px -2px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  right: 9px;
  .container {
    max-width: 926px;
  }
  .sig-preview {
    position: relative;
    padding-left: 5px;
    display: flex;
    flex: 1 1;
    border-bottom: 1px solid black;
    max-width: 90%;
    &:after {
      content: "Signature Preview";
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 13px;
      opacity: 0.5;
      position: absolute;
      left: 0;
      top: 100%;
    }
  }
}

#sig-label {
  pointer-events: none;
  position: absolute;
  top: 18px;
  left: 0.75rem;
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
  width: auto;
  padding: 0 5px;
  font-size: 12px;
  color: #777;
  transform: translateY(calc(-50% - 18px));
  background: white;
  z-index: 3;
}

.sig-canvas {
  border: 2px solid $blue;
  // border-style: solid inset solid solid;
  // border: 1px solid #ced4da;
  width: 100% !important;
  // height: calc(100% - 2.25rem) !important;
  // height: 100% !important;
  border-radius: 0.25rem;
}

#sig-container {
  > div {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background: $blue;
    color: white;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    p {
      line-height: 1.2;
      margin-bottom: 5px;
    }
  }
}

// #clear-sig-btn {
//   position: absolute;
//   bottom: 5px;
//   right: 10px;
// }

// #toc {
//   a {
//     display: block !important;
//   }
// }

@media (max-width: 1459px) {
  #ioc-btn {
    display: block !important;
  }
  #steps {
    box-shadow: $boxShadow;
    z-index: 2;
    opacity: 0;
    transform: translateX(-100%);
    transition: transform $transition, opacity $transition;
    pointer-events: none;
    &.show {
      opacity: 1;
      transform: none;
      pointer-events: all;
    }
    form {
      .form-row {
        flex-direction: column;
      }
    }
    .department {
      .team {
        columns: 1;
      }
    }
  }
  #container {
    padding-left: 47px;
  }
  #invert-doc {
    margin-left: -87px;
  }
}

@media (max-width: 1300px) {
  // #steps {
  //   // min-width: 520px;
  //   bottom: auto;
  //   height: 25vh;
  //   right: 0;
  //   max-width: 100vw;
  //   background: rgba(242, 248, 253, 0.8);
  //   backdrop-filter: blur(5px);
  //   box-shadow: 0px 14px 19px -14px rgba(0, 0, 0, 0.2);
  //   z-index: 2;
  //   top: 99px;
  //   #toc {
  //     margin-bottom: 0;
  //   }
  // }
  #document {
    // margin-left: 530px;
    // margin: 0 auto !important;
    // margin-top: 30vh !important;
    // z-index: 1;
  }
  #add-section {
    z-index: 2;
    padding: 15px;
    .form-group {
      min-width: 150px;
    }
  }
  #top-bar {
    height: 49px;
    .btn-group {
      // padding-right: 50px;
    }
    #doc-title {
      font-size: 1rem;
    }
  }
}
