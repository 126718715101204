@import "../../style-variables.scss";

#user-menu {
  pointer-events: all;
  position: fixed;
  top: 2px;
  right: 0;
  z-index: 1031;
  // transform: translateY(-50%);
  img {
    border-radius: 50%;
    width: 30px;
    height: auto;
  }
  .filter-option {
    padding: 0.25rem 1.5rem;
  }
  .dropdown-toggle::after {
    visibility: hidden;
  }
  .dropdown-menu {
    text-align: center;
    white-space: nowrap;
    padding-top: 0;

    #username {
      padding-top: 0.5rem;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }
}

#user-nav {
  li,
  a {
    padding: 0.75rem;
    justify-content: center;
  }
  .name {
    padding-top: 0;
    padding-bottom: 8px;
  }
  .switch {
    padding: 0;
    label {
      width: 100%;
      height: 100%;
      padding: 0.75rem;
      justify-content: center;
    }
  }
}
