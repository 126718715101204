@import "../../../style-variables.scss";

#steps {
  scroll-behavior: smooth;
  overflow-y: scroll;
  font-size: 12px;
  position: fixed;
  left: 0;
  top: 100px;
  bottom: 0;
  padding-top: 16px;
  padding-bottom: 80px;
  padding-right: 8px;
  background: #f8f9fa;
  box-shadow: inset -14px 0px 19px -14px rgba(0, 0, 0, 0.2);

  &.approved {
    // display: none;
  }
  .accordion {
    .card-body {
      overflow: auto;
    }
  }
  table {
    .btn {
      font-size: 10px;
    }
    .deliverable-item,
    .deliverable-category {
      // cursor: pointer;
      // &:hover {
      //   background: darken(#fafafa, 3%);
      // }
      th[scope="row"] {
        width: 22px;
        text-align: center;
      }
    }

    th.editable {
      &.selected {
        box-shadow: none;
        background: $link !important;
        color: white;
        ~ td {
          background: rgba($link, 0.08);
        }
      }
    }
    td {
      > div {
        display: inline-block;
      }
    }
  }
  .card {
    // border-left: none;
    // border-right: none;
  }
  .card-header {
    font-size: 16px;
    cursor: pointer;
    position: relative;
    .btn-group,
    .dropdown {
      position: absolute;
      right: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
    }
    // .btn {
    //   color: inherit;
    //   opacity: 0.3;
    //   font-size: 20px;
    //   &:hover {
    //     opacity: 0.8;
    //   }
    // }
  }
  .card-body {
    // background: white;
    padding: 10px;
  }
  // .accordion {
  //   &:last-of-type {
  //     .card {
  //       border: 1px solid rgba(0, 0, 0, 0.125);
  //     }
  //   }
  // }
  .department {
    .form-group {
      margin-bottom: 0rem;
    }
    .team {
      columns: 2;
      > div {
        break-inside: avoid;
      }
      label {
        margin-bottom: 0;
      }
    }
  }
  .total-table {
    // max-width: 50% !important;
    margin-right: 1px;
    .fit {
      min-width: 86px;
    }
  }
  .custom-checkbox {
    font-size: 1rem;
  }
  max-width: 320px;
  @media (min-width: 1460px) {
    max-width: 30vw;
    min-width: 437px;
  }

  // .MuiInputBase-adornedEnd {
  //   padding-right: 0;
  // }
}

#accordions {
  width: 100%;
}

.sub-total.hidden {
  display: none;
}

#attached-files {
  margin: 1rem 0;
  > * {
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  a {
    pointer-events: all !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// .MuiAccordion-root.Mui-expanded {
//   margin: 0 !important;
// }

.MuiAccordion-root.Mui-expanded:before {
  opacity: 1 !important;
}
