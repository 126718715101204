@import "../../../style-variables.scss";

header {
  p {
    margin-bottom: 0;
  }
  .logos {
    margin-bottom: 30px;
  }
}

#tn-info,
#contact-info {
  background: inherit;
  width: 100%;
  table-layout: fixed;
  td {
    vertical-align: top;
  }
}

#tn-info {
  p {
    margin-bottom: 0 !important;
    white-space: nowrap;
  }
}

#contact-info {
  td:first-of-type {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  td:last-of-type {
    padding-left: 15px;
  }
  strong {
    font-size: 80%;
  }
}

// hr {
//   border-top: 1px solid rgba(0, 0, 0, 0.3);
// }

#doc-title {
  padding: 0 7px;
  margin: 0;
}

.company-logo-container {
  text-align: right;
  position: relative;
  &.no-logo,
  &:hover {
    label {
      outline: 3px dashed rgba($link, 0.46);
      background: rgba($link, 0.08);
    }
  }
  &.no-logo {
    label {
      opacity: 0.8;
    }
    &:hover {
      label {
        opacity: 1;
      }
    }
  }
  label {
    font-weight: bold;
    color: rgba($link, 0.46) !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity $transition;
  }
  svg {
    font-size: 25px;
  }
  img {
    max-width: 100%;
    width: auto;
    max-height: 60px;
  }
}

input {
  &#logo-upload, &.company-logo  {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
  }
}

// #container:not(.approved) .document {

@media (min-width: 1460px) {
  #container .document {
    margin-left: calc(30vw + 30px);
  }
  #options-btn {
    display: none !important;
  }
}

.document {
  font-family: Arial, sans-serif;
  max-width: 926px !important;
  margin: 0 auto;

  [class^="MuiTypography"] {
    font-family: Arial, sans-serif;
  }

  table {
    background: #fafafa;
    .editable {
      &:hover {
        // background: inherit;
      }
    }
    .deliverable-item,
    .deliverable-category {
      th[scope="row"] {
        width: 32px;
        text-align: center;
      }
    }

    .fit {
      width: 130px;
    }
  }

  span.deliverables-table {
    table {
      pointer-events: none;
      a {
        color: inherit;
      }
    }
  }
}

#document {
  min-width: 875px;
  position: relative;
}

#invert-doc {
  position: sticky;
  // bottom: 100%;
  left: 0;
  top: 5px;
  margin-left: -54px;
  margin-top: -110px;
}

section {
  padding-top: 1rem;
  // padding: 10px;
  position: relative;
  transition: margin-bottom $transition, background $transition !important;
  &:after {
    content: "Adding Section Here";
    height: 0;
    transition: height $transition, opacity $transition;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    color: rgba($link, 0.5);
    background: rgba($link, 0.08);
    border: 2px dashed rgba($link, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(100% + 7.5px);
    left: 0;
    right: 0;
  }
  hr:first-child {
    margin-top: -16px;
    height: 3px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  hr:last-child {
    margin-bottom: 0;
  }
  button {
    display: none;
  }
  &:hover {
    .deliverable-toolbar {
      > * {
        opacity: 0.5;
        pointer-events: all !important;
      }
    }
  }
  h4 {
    font-size: 20px;
  }
  &.active {
    background: rgba($link, 0.08);
  }
  &.adding-below {
    margin-bottom: 115px;
    &:after {
      height: 100px;
      opacity: 1;
    }
  }

  // .delete-btn {
  //   position: absolute;
  //   left: 100%;
  // }
  // .block {
  //   *:last-child {
  //     margin-bottom: 0;
  //   }
  // }
  &:not(.deliverable-item) {
    // padding-top: 0;
    .section-title {
      margin-top: -0.9rem;
    }
  }
  img {
    max-width: 100% !important;
  }
}

.separator {
  margin-top: -10px;
}

.react-contextmenu-wrapper {
  width: 100%;
  // > section {
  //   height: 100%;
  // }
}

.react-contextmenu {
  .dropdown-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      margin-left: 30px;
      font-size: 22px;
    }
  }
}

#index-of-contents,
#toc {
  // margin-top: -30px;
  counter-reset: tocdeliverable appendix;
  .appendix-item {
    a {
      &:before {
        counter-increment: appendix;
        content: "Appendix " counter(appendix, upper-roman) ": ";
      }
    }
  }
  .appendix-item ~ .deliverable {
    padding-left: 35px;
    a {
      display: inline-block;
      position: relative;
      padding-left: 5px;
      &:before {
        position: absolute;
        top: 0px;
        right: 100%;
        white-space: nowrap;
        counter-increment: tocdeliverable;
        content: counter(tocdeliverable) ". ";
        color: $link;
        pointer-events: none;
      }
    }
  }
  ul {
    margin-bottom: 20px;
    padding: 0;
    list-style: none;
    li {
      border-bottom: 1px dotted;
      &:first-of-type {
        border-top: 1px dotted;
      }
    }
  }
  hr {
    margin-bottom: 0;
  }
  .company-name {
    color: inherit;
  }
}

#toc {
  padding: 1rem;
  font-size: 0.9rem;
  li {
    padding: 2px 0;
  }
}

#content {
  .existing-spend,
  .pass-through,
  .new-spend,
  .quantity,
  .total,
  .total-table .fit {
    width: 127px;
  }
}

#steps {
  .existing-spend,
  .pass-through,
  .new-spend,
  .quantity,
  .total,
  .total-table .fit {
    width: 98px;
  }
}

#content,
#reorder,
#pdf {
  counter-reset: deliverable appendix;
  margin-bottom: 1rem;
  section.deliverable-item {
    padding-left: 40px;
    list-style: none;
    position: relative;
    .section-title {
      color: black !important;
      font-weight: bold;
    }
    hr {
      margin-left: -25px;
      &:first-of-type {
        height: 0;
        border-bottom: 0;
      }
    }
    &:before {
      position: absolute;
      top: 19px;
      right: calc(100% - 36px);
      counter-increment: deliverable;
      content: counter(deliverable) ". ";
      font-size: 1.2rem;
    }
  }
  .appendix-item {
    .section-title {
      color: $orange;
      position: relative;
      &:before {
        counter-increment: appendix;
        content: "Appendix " counter(appendix, upper-roman) ": ";
      }
    }
    .block {
      + hr {
        display: none;
      }
    }
  }
  tr.no-deliv {
    display: none;
  }
}

#pdf {
  section > .section-title:first-child {
    margin-top: 0;
  }
  #invert-doc {
    display: none;
  }
}

.company-name {
  color: $orange;
}

section.reordering {
  outline: 1px solid rgba($link, 0.2);
  cursor: move;
  padding: 0.5rem;
  h4 {
    font-size: 1rem;
    margin: 0;
  }
  hr {
    display: none;
  }
  &.deliverable-item {
    &:before {
      top: 11px !important;
    }
  }
}

span.drag-handle {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateX(-50%);
  line-height: 0;
}

body {
  > section {
    background: white;
    box-shadow: $boxShadow;
  }
}

#pages {
  color: black;
  width: $pageW;
  margin: auto;
}

#import-modal {
  .search {
    margin-bottom: 1rem;
  }
  #import-results {
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: calc(100vh - 20.75rem);
    overflow-y: auto;
    .editable {
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      .small {
        > *:last-child {
          margin-bottom: 0;
        }
      }
      &.selected {
        background: rgba($green, 0.15);
      }
    }
  }
}

#team {
  .form-group {
    margin: 5px 0;
    font-size: 1rem;
  }
  .department {
    &:last-of-type {
      hr {
        display: none;
      }
    }
  }
}

.gray-bg {
  background: #d9d9d9;
}

footer {
  margin-bottom: 40px;
  font-size: 14px;
  color: #808080;
  position: relative;
  > div {
    position: relative;
    padding: 3px 0;
    border-top: 1px solid;
    &:first-of-type {
      span {
        &:first-of-type {
          position: absolute;
          left: 0;
        }
      }
    }
    &:last-of-type {
      span {
        flex: 1;
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: right;
        }
      }
    }
  }
  span {
    white-space: nowrap;
  }
}

#sig-required {
  padding-left: 40px;
  padding-bottom: 10px;
  position: relative;
  em:before {
    content: " \2756";
    font-style: normal;
    position: absolute;
    left: 20px;
    top: 0;
  }
}

#sig-block {
  position: relative;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  > div {
    &:first-of-type {
      img {
        width: 150px;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    &:last-of-type {
      text-align: right;
      flex: 1;
      p {
        position: relative;
        // &:after {
        //   content: "___________________________";
        // }
        display: flex;
        justify-content: flex-end;
        span {
          padding-left: 5px;
          max-width: 275px;
          display: flex;
          flex: 1;
          // text-decoration: underline;
          border-bottom: 1px solid black;
        }
      }
    }
  }
  p {
    white-space: nowrap;
  }
}

// .payments-table {
//   .currency.has-value {
//     &:before {
//       content: "$" !important;
//     }
//   }
// }

.sig-preview {
  font-family: "Cedarville Cursive", cursive;
  position: relative;
  height: 24px;
  font-size: 1.9rem;
  line-height: 1.4rem;
  div {
    position: absolute;
    top: -15px;
    left: 6px;
    font-size: 1.8rem;
  }
  canvas,
  img {
    position: absolute;
    left: 0;
    bottom: 0;
    // max-height: 60px !important;
  }
  canvas {
    opacity: 0;
  }
  img {
    margin-left: 5px;
    transform: none !important;
  }
}

.too-tall {
  background: rgba(#dc3545, 0.2);
}
