@import "../../../style-variables.scss";

.deliverable-toolbar {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > * {
    cursor: pointer;
    opacity: 0;
    pointer-events: none;

    &:hover {
      opacity: 1 !important;
    }
  }
}

.deliverable-container {
  position: relative;
  // page-break-inside: avoid;
  .delete-deliverable {
    font-size: 1.4rem;
  }

  .add-below {
    position: relative;
    left: 0;
  }

  &:hover {
    .deliverable-toolbar {
      > * {
        opacity: 0.5;
        pointer-events: all !important;
      }
      .add-below {
        display: block;
      }
    }
  }

  .badge {
    z-index: 1;
    position: relative;
  }

  .milestones {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      .editable {
        flex: 1;
        padding: 2px;
      }
      h4,
      h5 {
        display: inline-flex;
        margin-right: 5px;
        margin-bottom: 0;
      }
      &.new {
        margin-top: 1rem;
      }

      .delete-milestone {
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        .delete-milestone {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}
