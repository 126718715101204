@import "../../style-variables.scss";

#add-section,
#settings,
#add-doc {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // // padding: 30px;
  // // box-shadow: $boxShadow;
  // z-index: 5;
  // background: white;
  // width: 100%;
}

#settings-drawer {
  .MuiContainer-root {
    height: 100%;
  }
}
#settings-drawer,
#editor-drawer {
  position: relative;
  z-index: 1029;
  > div {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
      0px 16px 24px 2px rgba(0, 0, 0, 0.14),
      0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  }
}

#settings {
  position: relative;
  top: 0;
  // padding-left: 24px;
  // padding-right: 24px;
  padding-bottom: 24px;
  padding-top: 120px;
  // left: auto;
  // right: 8px;
  max-width: 360px;
  width: 50vw;
  z-index: 5;
  display: flex;
  height: 100%;
  // opacity: 0;
  // transform: translateX(100%);
  // transition: transform $transition, opacity $transition;
  // pointer-events: none;
  // background: rgba(white, 0.7);
  // backdrop-filter: blur(5px);
  > .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
  }
  // .form-row {
  //   flex-direction: column;
  // }
  &.show {
    opacity: 1;
    transform: none;
    pointer-events: all;
  }
  .floating-label.d-flex.p-0 {
    > div {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .MuiFormControl-root {
    width: 100%;
    margin: 8px 0;
  }

  // .MuiInputBase-adornedEnd {
  //   padding-right: 0;
  // }

  .MuiTab-root {
    min-width: 100px;
    width: 50%;
  }
}

#add-section {
  // opacity: 0;
  // transform: translateY(100%);
  // transition: transform $transition, opacity $transition;
  // pointer-events: none;
  max-height: calc(100vh - 50px);
  &.show {
    opacity: 1;
    transform: none;
    pointer-events: all;
  }
  .currency.has-value {
    font-size: 12px;
  }
  .form-control.currency {
    max-width: 125px;
  }
  .form-control.quantity {
    max-width: 90px;
  }
  .appended-button {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    margin-left: 6px;
  }
  .appended-button-group button {
    min-width: 0px;
  }
}

#add-doc {
  position: relative;
  background: rgba(white, 0.8);
  backdrop-filter: blur(5px);
  // padding: 10px;
  padding-top: 5px;
  padding-bottom: 13px;
  top: auto;
  h3,
  hr,
  br,
  // label,
  .close,
  #additional-settings, #revisions, .nav-tabs {
    display: none;
  }
  #main-tab {
    form {
      display: flex;
      align-items: center;
      margin: 0 -5px;
      > div {
        flex: 1;
        margin: 0 5px;
      }
      .MuiFormControl-root {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  .tab-content {
    margin: 0;
  }
  .form-group {
    width: 100%;
    margin: 0;
  }
  .tab-pane {
    margin-left: -5px;
    margin-right: -5px;
    display: flex;
    > .form-row {
      flex: 1;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  // .floating-label.react-select {
  //   div[class$="placeholder"] {
  //     color: #6c757d;
  //   }
  // }
}

.nav-tabs {
  display: flex;
  > a {
    flex: 1;
    text-align: center;
  }
}

#additional-settings {
  margin-top: 1rem;
}

#revisions {
  .revision-list {
    position: relative;
    padding-left: 16px;
    counter-reset: list-number;
    &.locked {
      &:before {
        content: "You must save current changes before loading a previous revision!";
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(black, 0.5);
        backdrop-filter: blur(5px);
        padding: 15px;
        color: white;
        z-index: 1;
        font-size: 20px;
        text-align: center;
      }
    }
    .revision {
      box-shadow: none;
      position: relative;
      padding: 5px;
      flex-direction: column;
      align-items: flex-start;
      &:before {
        position: absolute;
        right: 100%;
        top: 5px;
        margin-right: 2px;
        counter-increment: list-number;
        content: counter(list-number) ".";
      }
      span:not(.badge) {
        display: block;
      }
      // .badge {
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      // }
      &.selected {
        background: rgba($green, 0.15);
        // &:after {
        //   content: " (Current)";
        //   display: inline-block;
        // }
      }
    }
  }

  .MuiChip-root {
    min-width: 94px;
  }
}

.tab-content {
  margin-top: 20px;
}

.internal-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  input { height: 50px; color: white !important; }
  input::selection{ background: transparent; }
  input::-moz-selection {   background: transparent;  }
  
  img { position: absolute; max-height: 40px; z-index: 999; }
}

.internal-logo-option{
  text-align: center;
  border-top: 1px solid #ddd;
  padding: 10px 0px;

  &:first-of-type{ border-top: none; }

  img { max-height: 40px; }
}
.internal-logo-option:hover{
  cursor: pointer;
  background-color: rgba(18, 183, 150, 0.05);
}