@import "../../../style-variables.scss";

#top-bar {
  // background: rgba(white, 0.8);
  // backdrop-filter: blur(5px);
  top: 50px;
  box-shadow: $boxShadow;
  padding: 5px !important;
  // ~ hr {
  //   margin-left: 10px;
  //   margin-right: 10px;
  // }
  .btn-group-sm > .btn,
  .btn-sm:not(#approved-btn) {
    padding: 0.25rem 0.5rem;
    font-size: 1.275rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-group > .btn-group:not(:first-child),
  .btn-group > .btn:not(:first-child) {
    margin-left: 0px;
  }

  .MuiButtonGroup-grouped {
    border: none !important;
  }

  .title-container {
    position: relative;
    flex: 1 1 0%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.blur-mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(white, 0.8);
  backdrop-filter: blur(5px);
}

#add-section-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  font-size: 40px !important;
  opacity: 1;
  transition: opacity $transition;
  pointer-events: all;
  svg {
    filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.2));
  }
}

.editor-open {
  #add-section-btn {
    opacity: 0;
    pointer-events: none;
  }
}

#save-btn,
#print-btn {
  width: 40px;
}

#approved-btn {
  cursor: pointer;
}

#home-btn {
  position: absolute;
  left: 0px;
  margin-left: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

#undo-redo {
  margin-right: 10px;
}

.editor-open {
  #undo-redo {
    visibility: hidden;
  }
}

#leave-preview-btn {
  svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
}

#doc-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.action-needed {
    color: var(--warning);
    position: relative;
    padding-left: 40px;
    &:before {
      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='rgb(255, 193, 7)' d='M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z'/></svg>");
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      height: 24px;
      // font-size: 1rem;
    }
    // &:after, #extend-btn {
    //   display: none;
    // }
  }
}

#settings-btn {
  position: relative;
  svg {
    &:nth-of-type(2) {
      position: absolute;
      top: -10px;
      right: 0;
      color: var(--danger);
      width: 19px;
    }
  }
}
