@import "../../../style-variables.scss";

#reports {
  #filter-results {
    width: 100%;
    min-width: 310px;
    white-space: nowrap;
    display: flex;
    // > div {
    //   margin-right: 10px;
    //   background: var(--light);
    //   padding: 5px;
    //   border-radius: 0.2rem;
    // }
  }
  #report-filters {
    @media (max-width: 1080px) {
      margin-top: 10px;
      flex-direction: column-reverse;
      > div {
        width: 100%;
        max-width: 100%;
      }
    }
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .active-criteria {
    position: relative;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    span {
      &:not(:first-of-type) {
        &:before {
          content: ", ";
        }
      }
    }
    .close {
      font-size: 19px;
      margin-left: 10px;
      margin-top: -2px;
      // margin-top: -5px;
      // opacity: 0;
      pointer-events: none;
    }
  }
}

#currency-dropdown {
  .MuiFormControlLabel-root {
    margin: 0;
  }
  .MuiListItem-root {
    padding: 0;
  }
  .MuiFormGroup-root {
    width: 100%;
  }
}
