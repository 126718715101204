@import "../../../style-variables.scss";

#batch-modal {
  .company-logo-container {
    text-align: center;
    min-height: 56px;

    img {
      max-height: 49px;
    }
  }

  .batch-progress-bar {
    min-height: 8px !important;
  }

  .disabled-modal-content {
    pointer-events: none !important;
    opacity: 0.5;
  }

  .mce-content-body {
    height: 350px;
    > *:last-child {
      margin-bottom: 0;
    }

    &.mce-edit-focus {
      box-shadow: inset 0 0 0px 1px #12b796;
      border-color: #12b796;
    }
  }

  #quick-add-clone {
    flex: 1;
    .MuiPaper-root {
      opacity: 0.6;
      transition: opacity $transition;
      cursor: pointer;
      border-radius: 4px;
      border: 3px dashed rgba($link, 0.46);
      background: rgba($link, 0.08);
      flex: 1;
      color: rgba($link, 0.46);
      &:hover {
        opacity: 1;
      }

      svg {
        font-size: 5rem;
      }
    }
  }

  div[id^="clone-toolbar"] {
    position: relative;
    > div {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
