@import "../../style-variables.scss";

#admin {
  h5 + .btn {
    padding: 0.25rem 0.5rem;
    font-size: 1.275rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-table {
    thead,
    tbody,
    tr,
    th,
    td {
      display: flex;
      align-items: center;
    }
  }
}

#user-list {
  tbody {
    // height: auto;
  }
  tr {
    // display: table-row;

    &.no-results {
      height: 40px;
    }

    td,
    th {
      &:nth-of-type(1) {
        width: 30%;
      }
      &:nth-of-type(2) {
        width: 60%;
      }
      &:last-of-type {
        margin-right: 0;
        margin-left: auto;
      }
    }

    img {
      border-radius: 50%;
      width: 28px;
      margin-right: 10px;
    }
    &.no-results {
      border: 0 !important;
    }
  }
}

#team-list {
  tr {
    td,
    th {
      &:first-of-type {
        flex: 1;
      }
      &:nth-of-type(2) {
        width: 40%;
      }
      &:nth-of-type(3) {
        width: 25%;
      }
      // &:last-of-type {
      //   width: 50px;
      // }
    }
  }
  tbody {
    tr {
      &:hover {
        background: rgba(#0275d8, 0.05);
        cursor: pointer;
      }
    }
  }
}

#permission-list {
  tr {
    td,
    th {
      &:nth-of-type(1) {
        width: 50%;
      }
      &:nth-of-type(2) {
        width: 170px;
        > div {
          width: 100%;
        }
      }
      &:last-of-type {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }
  .react-select * {
    cursor: pointer;
  }
  .react-select__indicator {
    transform: rotate(-90deg);
  }
  .react-select__menu {
    opacity: 0;
    left: 100%;
    margin: 0 2px;
    transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;
    &.top {
      transform: translateY(-40px);
      opacity: 1;
    }

    &.bottom {
      transform: translateY(40px);
      opacity: 1;
    }
  }
}
