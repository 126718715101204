@import "../../style-variables.scss";

.title {
  margin: 0;
}

// #doc-list {
//   thead,
//   tbody,
//   tr,
//   th {
//     display: flex;
//   }
//   tr {
//     td,
//     th {
//       &:first-of-type {
//         flex: 1;
//       }
//       &:nth-of-type(2) {
//         width: 140px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-direction: column;
//       }
//       &:last-of-type {
//         width: 150px;
//       }
//     }
//   }
// }

#status-dropdown {
  .MuiFormControlLabel-root {
    margin: 0;
    padding-right: 12px;
  }
  .MuiListItem-root {
    padding: 0;
  }
  .MuiFormGroup-root {
    width: 100%;
  }
}

.active-criteria {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: flex;
  align-items: center;
  .MuiChip-root {
    margin-right: 5px;
  }
  .icon {
    width: 12px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 5px;
    // max-height: 10px;
    // max-width: 10px;
  }
  span {
    &:not(:first-of-type) {
      &:before {
        content: ", ";
      }
    }
  }
  .close {
    margin-left: 10px;
    // margin-top: -5px;
  }
}

@media (max-width: 990px) {
  .doc-title {
    // font-size: 16px;
    max-width: 45vw;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
