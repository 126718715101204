@import "../../../style-variables.scss";

.expand-number::-webkit-inner-spin-button,
.expand-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.expand-number {
  max-width: 36px;
  margin-left: -1px;
  text-align: center;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #6c757d !important;
    text-align: center;
    opacity: 1; /* Firefox */
    position: relative;
    top: -2px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #6c757d !important;
    text-align: center;
    position: relative;
    top: -2px;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #6c757d !important;
    text-align: center;
    position: relative;
    top: -2px;
  }
}

.creatable-select {
  [id^="react-select"] {
    position: relative;
    left: 0.15rem;
  }
}

.lockout {
  pointer-events: none;
  .MuiAutocomplete-root {
    visibility: hidden;
  }
  // &:before {
  //   content: "Please select a category before entering a title";
  //   position: absolute;
  //   z-index: 1;
  //   left: 0;
  //   right: 0;
  //   margin: 0 auto;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   text-align: center;
  //   color: var(--danger);
  // }
}

.expand-number {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.tox.tox-tinymce--toolbar-bottom .tox-editor-header,
.tox.tox-tinymce-inline .tox-editor-header {
  border-bottom: 0;
}
