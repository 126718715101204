@import "../style-variables.scss";
@import url("https://fonts.googleapis.com/css?family=Cedarville+Cursive&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import "tables.scss"; // bootstrap table styles from SOW 1.0

*:focus:not(:focus-visible) {
  outline: 0 !important;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #12b796;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
}

::-moz-selection {
  color: white;
  background: var(--info);
}

::selection {
  color: white;
  background: var(--info);
}

.text-danger {
  color: var(--danger);
}

// *,
// *::before,
// *::after {
//   box-sizing: border-box;
// }

html,
body {
  background-color: #f8f9fa !important;
  // overflow: hidden;
  height: 100%;
  width: 100%;
  > img,
  canvas {
    display: block;
  }
}
//
// html {
//   overflow-y: scroll;
// }

body {
  scroll-behavior: smooth;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  // overflow: auto !important;
  &.tox-dialog__disable-scroll {
    overflow: unset !important;
  }
  scrollbar-color: lighten(#555, 10%) transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px black;
    // background: lighten(#999, 35%);
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten(#999, 20%);
    // outline: 1px solid lighten(#999, 20%);
    border-radius: 5px;
  }
  * {
    scrollbar-color: lighten(#999, 20%) transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      // background: lighten(#999, 35%);
      // box-shadow: inset 0 0 6px black;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: lighten(#999, 20%);
      // outline: 1px solid lighten(#999, 20%);
      border-radius: 5px;
      &:hover {
        background-color: lighten(#999, 10%);
      }
    }
  }
}

// body.fontLoaded {
//   font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
// }

a {
  color: var(--primary);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    text-decoration: none;
  }
}

p {
  margin-top: 0;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

// * {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

#app-container {
  scroll-behavior: smooth;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &.doc-view {
    overflow-y: auto;
    top: 100px;
    #container {
      padding-top: 20px;
      margin-top: 0;
      #pdf {
        margin-top: 0;
      }
    }
  }
}

// body:not(.loaded-pdf) {
//   #app-container {
//       &.doc-view {
//         overflow-y: auto;
//         top: 100px;
//         #container {
//           padding-top: 20px;
//           margin-top: 0;
//           #pdf {
//             margin-top: 0;
//           }
//         }
//       }
//   }
// }

.capitalize {
  text-transform: uppercase;
}

.no-access {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  padding: 20px;
  img {
    margin-top: 20px;
    width: 40vw;
  }
}

#print {
  position: relative;
  top: 60px;
  text-align: center;
  flex-direction: column;
}

#app {
  background-color: #fafafa;
  min-height: 100%;
  min-width: 100%;
}

#app-container {
  // &:not(.super-admin) {
  //   #home {
  //     padding-top: 65px;
  //   }
  //   #spinner {
  //     top: 0;
  //   }
  //   #top-nav {
  //     backdrop-filter: none;
  //     background: transparent;
  //     pointer-events: none;
  //     .nav {
  //       display: none;
  //     }
  //     + .container {
  //       margin-top: 0;
  //     }
  //   }
  //   #top-bar {
  //     top: 0 !important;
  //   }
  //   #steps {
  //     top: 49px;
  //   }
  //   #settings {
  //     padding-top: 70px;
  //   }
  //   #add-doc {
  //     top: 0;
  //     bottom: auto;
  //   }
  // }
  // &.super-admin {
  //   #home-btn {
  //     display: none;
  //   }
  // }
}

.fade:not(.show) {
  pointer-events: none;
}

.focus-element {
  &:before {
    content: "";
    position: absolute;
    background: rgba(black, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.ff {
  #top-nav {
    background: #f7f7f7;
  }
  .blur-mask {
    background: white;
  }
  .table-bordered {
    border: 0;
  }
}

.needs-attention {
  [class*="-control"] {
    border: 1px solid var(--danger) !important;
    box-shadow: 0 0 0 1px var(--danger) !important;
  }
}
.container {
  @media (max-width: 1200px) {
    max-width: 100% !important;
  }
}

#top-nav {
  backdrop-filter: blur(5px);
  height: 50px;
  // display: flex;
  // align-items: center;
  // background: #f7f7f7eb;
  z-index: 1031;
  // padding-right: 0 !important;
  ~ .container,
  ~ .MuiContainer-root {
    margin-top: 65px;
  }
  &.no-user {
    display: none;
    // + #spinner {
    //   top: 0;
    //   > div {
    //     margin-top: 50px;
    //   }
    // }
  }
  .nav-link {
    height: 50px;
    font-size: 22px;
    &:hover {
      // background: darken(#f7f7f7, 2%);
    }
    &.active {
      background: darken(#f7f7f7, 5%);
    }
  }

  #recent-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      user-select: none;
    }
  }
  .dropdown-toggle {
    font-size: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      display: none;
    }
  }
}

#spinner {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1399;
  // background: white;
  background-color: #f8f9fa !important;
  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  &.main-spinner {
    z-index: 9999999;
  }

  circle {
    animation: none !important;
  }
}

.orange {
  color: $orange;
}

.green {
  color: $green;
}

.red {
  color: var(--danger);
}

.hidden {
  visibility: hidden !important;
}

ul {
  list-style-type: square;

  ul {
    list-style-type: circle;
  }
}

li {
  word-break: break-word;
}

// p {
//   + ul,
//   + ol {
//     margin-top: -1rem;
//   }
// }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
}

.btn:focus,
.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: none !important;
}

.btn-link {
  padding: 0;
}

.btn:disabled {
  opacity: 0.5;
}

form.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.small {
  font-size: 80%;
  font-weight: 400;
}

.listing {
  margin: -1rem;
  margin-bottom: 1rem;
  overflow-y: scroll;
  padding: 16px;
  padding-right: 9px;
  padding-bottom: 0;
  height: calc(100vh - 300px);
  scroll-behavior: smooth;
  .MuiPaper-root {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.5rem;
    transition: background $transition;
    &:hover {
      background: rgba($link, 0.05);
    }

    .list-name {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .doc-title {
      display: inline-block;
      margin: 0;
      font-weight: normal;
    }

    .list-meta {
      display: flex;
    }

    .small {
      width: 200px;
    }

    .MuiChip-root {
      width: 96px;
    }
    &.deleting {
      position: relative;
      pointer-events: none;
      .doc-title {
        color: var(--danger) !important;
        opacity: 0.6;
      }
      .dropdown,
      .small > * {
        visibility: hidden;
      }
      &:after {
        content: "Deleting...";
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        color: var(--danger);
        font-size: 1rem;
        text-align: center;
      }
    }
    &.master {
      .doc-title {
        color: var(--orange);
      }
    }
    &.action-needed {
      .doc-title {
        color: var(--warning);
      }
      .list-name {
        position: relative;
        &:before {
          content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='rgb(255, 193, 7)' d='M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z'/></svg>");
          // position: absolute;
          // left: -25px;
          // top: 50%;
          // transform: translateY(-50%);
          display: inline-block;
          height: 24px;
          margin-right: 5px;
          // font-size: 1rem;
        }
      }
    }
    &.action-needed.attention-needed {
      td.d-flex:after {
        left: -45px;
      }
    }

    .options-btn {
      margin-right: -7px;
      margin-left: 4px;
    }
  }
}

.options-menu {
  li {
    min-height: 44px;
  }
}

div.MuiListItemIcon-root {
  min-width: 36px;
}

div.MuiMenu-paper {
  min-width: 160px;
}

// div.MuiTooltip-popper {
//   pointer-events: none;
// }

button.MuiButton-root:not(.MuiButton-sizeSmall) {
  min-height: 40px;
}

div.MuiChip-root:not(.MuiButtonBase-root),
#top-bar .MuiChip-root {
  border-radius: 4px;
}

.MuiFormHelperText-root {
  display: none;
}

// div.MuiAutocomplete-popper .MuiPaper-root {
//   box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
//     0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
// }

// .Mui-disabled {
//   opacity: 0.5;
// }

.mce-content-body {
  padding: 10px;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  min-height: 150px;
  max-height: 360px;
  overflow-y: auto;

  &.mce-edit-focus {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  [contentEditable="false"],
  .mceNonEditable {
    cursor: default;
    background: #e9ecef;
    padding: 1px 3px 3px;
    border-radius: 0.25rem;
  }
}

// .tox-menu {
//   top: 0 !important;
// }

.tox-tinymce {
  border-bottom: 0 !important;
  border-top-right-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.tox .tox-collection--list .tox-collection__group {
  white-space: nowrap;
}

.tox-background-menu,
.tox-selected-menu {
  + .tox-collection--list {
    height: 50vh;
    max-height: 200px !important;
  }
}

.tox-toolbar__group {
  &.loading {
    position: relative;
    button {
      opacity: 0;
    }
    &:after {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: calc(50% - 0.5rem);
      top: calc(50% - 0.5rem);
      content: "";
      width: 1rem;
      height: 1rem;
      vertical-align: text-bottom;
      border: 0.15em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      animation: spinner-border 0.75s linear infinite;
    }
  }
}

.hidden-input {
  position: absolute;
  opacity: 0;
  height: 0;
  top: 0;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.currency {
  text-align: right;
  position: relative;
  white-space: nowrap;
  &.has-value {
    padding-left: 20px;

    &:before {
      content: "$";
      display: block;
      position: absolute;
      // border-right: 1px solid $border;
      left: -4px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      font-weight: normal;
      z-index: 4;
      opacity: 0.5;
      // display: inline-block;
      // margin-top: -2px;
      // opacity: 0.3;
    }
  }
  &.floating-label {
    padding-left: 5px;
    input:first-of-type {
      padding-left: 30px;
    }
    &:before {
      left: 10px;
    }
  }
  // ~ .currency {
  //   &:before {
  //     display: none;
  //   }
  // }
}

.quantity {
  text-align: right;
  position: relative;
  white-space: nowrap;
  &.has-value {
    padding-left: 20px;
    font-size: 12px;
    &:before {
      content: "#";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      font-weight: normal;
      z-index: 4;
    }
  }
  &.floating-label {
    padding-left: 5px;
    input:first-of-type {
      padding-left: 30px;
    }
    &:before {
      left: 10px;
    }
  }
}

.mce-content-body table,
.table,
.table-bordered {
  width: 100% !important;

  td,
  th {
    vertical-align: middle;
    border: 1px solid $border !important;

    &.fit {
      white-space: nowrap;
      width: 1%;
    }

    tr {
      width: 100%;
      display: inline-table;

      td {
        border: 0;
        border-top: 1px solid #dee2e6;
      }
    }
  }

  &.deliverables-table {
    tr:not(.sub-total) {
      td {
        &[colspan="4"],
        &[colspan="5"] {
          ~ td {
            display: none;
          }
        }
      }
    }
  }

  th.fit {
    text-align: center;
  }

  .discount {
    background: lighten($green, 60%);
  }

  .subsection {
    &.hide-sign {
      .currency {
        &:before {
          display: none;
        }
      }
    }

    &.hide-cells {
      .currency {
        display: none;
      }
    }
  }

  tfoot,
  thead {
    background: lighten($orange, 30%);
    font-weight: bold;
  }

  &.total-table {
    // float: right;
    margin-left: auto;
    margin-right: 0;
    .fit {
      text-align: left;
    }

    tfoot {
      background: lighten($orange, 5%);
    }
  }
  &.sub-total {
    tfoot {
      // background: lighten($orange, 18%);
      color: white;
    }
  }
}

.table-striped tbody tr {
  // &:nth-of-type(odd) {
  //   background: none;
  // }
  &:nth-of-type(odd) {
    background: lighten($orange, 30%);
  }
}

.EUR {
  // .table,
  // .table-bordered {
  .currency.has-value {
    &:before {
      content: "€";
    }
  }
  // }
}

.GBP {
  // .table,
  // .table-bordered {
  .currency.has-value {
    &:before {
      content: "£";
    }
  }
  // }
}

.MuiTable-root,
.table,
.table-bordered {
  &.USD,
  .USD {
    &.currency.has-value,
    .currency.has-value {
      &:before {
        content: "$" !important;
      }
    }
  }
  &.EUR,
  .EUR {
    &.currency.has-value,
    .currency.has-value {
      &:before {
        content: "€" !important;
      }
    }
  }
  &.GBP,
  .GBP {
    &.currency.has-value,
    .currency.has-value {
      &:before {
        content: "£" !important;
      }
    }
  }
}

.input-group-text {
  min-width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control:focus {
  border-color: $blue;
  box-shadow: 0 0 0 1px $blue;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 0.5;
}

.input-group-sm {
  .input-group-text {
    min-width: auto;
  }
}

.react-datepicker {
  border: 0;
  border-color: #ced4da;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #d2d2d2;
}

.react-datepicker-wrapper {
  .form-label {
    left: 0.45rem !important;
  }
}

.react-datepicker__triangle {
  display: none;
}

.close {
  font-weight: normal;
  text-shadow: none;
}

.form-group {
  .close {
    position: absolute;
    right: 10px;
    top: 50%;
    line-height: 1;
    transform: translateY(-50%);
  }
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 115px;
  width: 100%;
  input {
    padding-right: 36px;
    width: 100%;
  }
  .search-actions {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    > div {
      // margin-right: 7px;
    }
  }
}

#search-docs {
  width: 100%;
  max-width: 260px;
  .search {
    margin: 0;
  }
}

#search-results,
#filter-results {
  font-weight: normal;
  font-size: 12px;
}

#filter-results {
  span {
    position: relative;
    &:not(:first-of-type) {
      &:before {
        content: ", ";
      }
    }
  }
  > div {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    &:hover {
      .close {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}

body.modal-open {
  overflow: visible !important;
}

.modal-header {
  .modal-title {
    margin-bottom: 0;
  }
}

.modal {
  .floating-label.react-select {
    label {
      left: 0.45rem;
    }
  }
}

.mega-search {
  display: flex;

  > *,
  > * > * {
    flex: 1;
  }
}

.no-existing-spend {
  .existing-spend {
    display: none;
  }
}

.no-pass-through {
  .pass-through {
    display: none;
  }
}

.no-existing-spend.no-pass-through {
  .total-table {
    .currency {
      text-align: right !important;
    }
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
  .input-group-text svg {
    margin-top: -2px;
  }
}

.dropdown-toggle::after {
  top: 0.1rem;
  position: relative;
}

.dropdown-menu {
  transform-origin: center;
  // transform: scale(0.5);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  .dropdown-menu-container {
    transition: opacity 0.15s ease-in-out;
    transform: scale(0.9);
  }
  &.show {
    opacity: 1;
    // transform: scale(1)
    .dropdown-menu-container {
      transform: scale(1);
    }
  }
}

.dropdown {
  .dropdown-menu-container {
    position: relative;
    z-index: 1000;
    transition: transform 0.15s ease-in-out;
    transform: scale(0.8);
  }
  &.show {
    .dropdown-menu-container {
      transform: scale(1);
    }
  }
}

#confetti {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  > div > div {
    background-position: center;
    background-size: cover;
  }
}

// :root {
//   --input-padding-x: 0.75rem;
//   --input-padding-y: 0.75rem;
// }

.floating-label {
  position: relative;
  margin-bottom: 1rem;
  label:not(.custom-control-label) {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 0.75rem;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
    transform: translateY(-50%);
    width: auto;
    padding: 0 5px;
    color: #777;
  }
  textarea {
    min-height: 38px;
    + label:not(.custom-control-label) {
      top: 31%;
    }
  }
  input,
  select,
  textarea {
    padding-left: 0.75rem;
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::-moz-placeholder,
    &::placeholder {
      color: transparent;
    }
    &:not(:placeholder-shown) {
      // padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
      // padding-bottom: calc(var(--input-padding-y) / 3);
    }
  }

  .form-control::placeholder {
    color: transparent;
    opacity: 0.5;
  }

  // input:not(:placeholder-shown) ~ label:not(.custom-control-label),
  // select:not(:placeholder-shown) ~ label:not(.custom-control-label),
  // textarea:not(:placeholder-shown) ~ label:not(.custom-control-label) {
  input ~ label:not(.custom-control-label),
  select ~ label:not(.custom-control-label),
  textarea ~ label:not(.custom-control-label) {
    // padding-top: calc(var(--input-padding-y) / 3);
    // padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
    transform: translateY(calc(-50% - 18px));
    background: white;
    z-index: 3;
  }
  textarea ~ label:not(.custom-control-label) {
    top: 18px;
  }
}

.form-control-sm {
  + .form-label {
    // height: calc(1.5em + 0.5rem + 2px);
    // padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    // line-height: 1.5;
    // border-radius: 0.2rem;
  }
}

.select-sm {
  .react-select__control {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    min-height: 31px;
  }
  .react-select__indicators {
    margin-top: -5px;
  }
  .react-select__indicator {
    padding: 0;
    padding-left: 3px;
  }
  .react-select__indicator-separator {
    margin: 7px 0;
    margin-left: 2px;
    + .react-select__indicator {
      margin-right: -4px;
    }
  }
  .react-select__value-container {
    margin-top: -6px;
    padding-left: 2px;
  }
  .react-select__single-value {
    left: 0.15rem !important;
    max-width: 100% !important;
  }
  .react-select__menu {
    font-size: 0.875rem;
  }
  .form-label {
    font-size: 10px !important;
  }
  .react-select__menu-list {
    overflow-x: hidden;
  }
}

.react-select--is-disabled {
  .react-select__control {
    background: white;
    border-color: hsl(0, 0%, 80%);
  }
  .react-select__indicators {
    display: none;
  }
  .react-select__single-value {
    left: 0.15rem !important;
    right: 0;
  }
}

.react-select__menu {
  z-index: 5 !important;
}

.dropdown-filter {
  .custom-control.custom-checkbox {
    display: flex;
  }
  .custom-control-label {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .filter-option {
    padding: 0.25rem 0.75rem;
    label {
      cursor: pointer;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.disabled-children {
    li {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.custom-switch {
  cursor: pointer;
  * {
    cursor: pointer;
  }
  &.enabled {
    .custom-control-label {
      &:after {
        content: "😈";
        font-size: 11px;
        background-color: transparent !important;
        top: calc(0.25rem + 0px);
        left: calc(-2.25rem + 0px);
      }
    }
  }
}

#table-select {
  min-width: 290px;
}

.floating-label.react-select {
  label {
    font-size: 12px;
    color: #777 !important;
    // transform: translateY(calc(-50% - 18px)) !important;
    transform: none;
    top: auto;
    bottom: calc(100% - 5px);
    line-height: 0.6;
    background: white;
    z-index: 3;
  }
  div[class$="menu"] {
    z-index: 4;
  }
  div[class$="placeholder"] {
    color: #6c757d;
    opacity: 0.5;
    color: transparent;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div[class$="singleValue"] {
    left: 0.65rem;
  }
  div[class$="control"] {
    // border-color: #cedz4da;
    // &:hover {
    //   border-color: #ced4da;
    // }
  }
  .input-group-sm {
    label:not(.custom-control-label) {
      font-size: 10px;
      left: 0.4rem;
    }
  }
}

.input-group-sm {
  * {
    font-size: 0.875rem;
  }
}

.input-group {
  div[class$="container"] {
    flex: 1;
    > div {
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;
    }
  }
}

.input-group-sm {
  .input-group-prepend + input + label:not(.custom-control-label) {
    left: 2rem;
    padding: 1px;
  }
  input ~ label:not(.custom-control-label),
  textarea ~ label:not(.custom-control-label),
  select ~ label:not(.custom-control-label) {
    // input:not(:placeholder-shown) ~ label:not(.custom-control-label),
    // textarea:not(:placeholder-shown) ~ label:not(.custom-control-label),
    // select:not(:placeholder-shown) ~ label:not(.custom-control-label) {
    // padding-top: calc(var(--input-padding-y) / 3);
    // padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 10px;
    transform: translateY(calc(-50% - 16px));
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
  }
  div[class$="control"] {
    min-height: 31px !important;
  }
  div[class$="indicatorContainer"] {
    padding: 4px;
  }
  div[class$="Input"] {
    padding: 0px;
  }
}

#add-section {
  .sub-section-container {
    padding-top: 5px;
    padding-right: 36px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .form-control-sm + label:not(.custom-control-label) {
    font-size: 0.875rem;
  }
  // input.form-control-sm:not(:placeholder-shown) ~ label:not(.custom-control-label),
  // select.form-control-sm:not(:placeholder-shown) ~ label:not(.custom-control-label) {
  //   font-size: 10px;
  // }
}

input.form-control-sm ~ label:not(.custom-control-label),
textarea.form-control-sm ~ label:not(.custom-control-label),
select.form-control-sm ~ label:not(.custom-control-label) {
  font-size: 10px !important;
  transform: translateY(calc(-50% - 16px));
}

.form-group {
  select {
    text-indent: 0.25rem;
  }
}

.react-datepicker-popper {
  z-index: 5;
}

// #generated-tables, #content span.deliverables-table {
//   display: flex;
//   flex-direction: column;
// }

.total-table {
  align-self: flex-end;
}

span.by-line {
  opacity: 0.4;
}

input,
textarea,
select {
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    &:hover {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
    &:focus {
      border-width: 2px;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
  }
}

.list-table {
  padding-left: 0;
  .doc-title {
    margin: 0;
  }
  li {
    margin-bottom: 10px;
  }
  width: 100%;
  thead {
    tr {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    }
  }
  tbody {
    height: calc(100vh - 300px);
    overflow-y: scroll;
    width: 100%;
    width: calc(100% + 25px);
    padding-left: 25px;
    margin-left: -25px;
    flex-direction: column;
    scroll-behavior: smooth;
  }
  thead {
    background: inherit;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    tr {
      th {
        // float: left;
      }
    }
    padding-right: 8px;
  }
  tr {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  td,
  th {
    border: 0 !important;
  }
  .dropdown:not(.show) {
    opacity: 0;
    pointer-events: none;
  }
  .doc {
    &:hover {
      background: rgba(#0275d8, 0.05);
      .dropdown {
        opacity: 1;
        pointer-events: all;
      }
    }
    .small {
      line-height: 1.3;
      white-space: nowrap;
    }
    &.deleting {
      pointer-events: none;
      a {
        color: var(--danger);
      }
      .dropdown,
      .small > * {
        visibility: hidden;
      }
      .small {
        position: relative;
        &:after {
          content: "Deleting...";
          position: absolute;
          left: 5px;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          color: var(--danger);
          font-size: 1rem;
        }
      }
    }
    &.master {
      // background: rgba($green, 0.1);
      .doc-title {
        color: var(--orange);
      }
    }
    &.action-needed {
      // box-shadow: inset 0 0 0px 1px var(--warning);
      .doc-title {
        color: var(--warning);
      }
      td.d-flex {
        position: relative;
        &:before {
          content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='rgb(255, 193, 7)' d='M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z'/></svg>");
          position: absolute;
          left: -25px;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          height: 24px;
          // font-size: 1rem;
        }
      }
    }
    &.action-needed.attention-needed {
      td.d-flex:after {
        left: -45px;
      }
    }
  }
  .badge {
    width: 90%;
    vertical-align: middle;
    span {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

#doc-list {
  tr,
  th {
    display: flex;
  }
  tr {
    justify-content: flex-end;
    td,
    th {
      &:first-of-type {
        flex: 1;
      }
    }
  }
  .list-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    > a {
      max-width: 100%;
      display: inline-flex;
      align-items: center;
      .doc-title {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

// @import "dark.scss";
